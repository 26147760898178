var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pa-7",
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_vm.title ? _c('div', {
    staticClass: "pb-6"
  }, [_c('div', {
    staticClass: "font-bold font-size-h3"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")])]) : _vm._e(), _vm.tab === 1 ? _c('div', {
    staticClass: "mb-4 font-size-h3"
  }, [_vm._v("Let's start")]) : _vm._e(), _vm.tab === 2 ? _c('div', {
    staticClass: "mb-4 font-size-h3"
  }, [_c('div', {
    staticClass: "text-left"
  }, [_c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "small": "",
      "text": "",
      "depressed": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.tab = 1;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-arrow-left')
    }
  }), _vm._v(" Back to Signin ")], 1)], 1), _c('div', [_vm._v("Create account")])]) : _vm._e(), _vm.tab === 3 ? _c('div', {
    staticClass: "mb-4 font-size-h3"
  }, [_vm._v("Signin")]) : _vm._e(), _vm.tab === 5 ? _c('div', {
    staticClass: "font-size-h3"
  }, [_c('div', {
    staticClass: "text-left cursor-pointer bodyFont primary--text",
    on: {
      "click": function click($event) {
        _vm.tab = 3;
      }
    }
  }, [_vm._v(" Back to Singin ")])]) : _vm._e()]), _c('div', [_vm.tab === 1 ? _c('div', {
    staticClass: "mt-7",
    on: {
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.checkEmail.apply(null, arguments);
      }
    }
  }, [_c('v-text-field', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required|email',
      expression: "'required|email'"
    }],
    attrs: {
      "filled": "",
      "autofocus": "",
      "placeholder": "Enter your work email address"
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }, 'v-text-field', _vm.veeValidate('Email', 'Email'), false)), _c('div', {
    staticClass: "form-group d-flex flex-wrap justify-center align-center"
  }, [_c('v-btn', {
    staticClass: "main primary",
    attrs: {
      "loading": _vm.busy,
      "disabled": _vm.busy || _vm.errors.any()
    },
    on: {
      "click": _vm.checkEmail
    }
  }, [_vm._v(" Next ")])], 1), _c('social-auth', {
    attrs: {
      "button-text": "Continue with"
    },
    on: {
      "loading": function loading($event) {
        _vm.busy = $event;
      },
      "success": _vm.afterSignIn
    }
  })], 1) : _vm._e(), _vm.tab === 2 ? _c('div', [_c('signup-form', {
    attrs: {
      "email": _vm.form.email
    },
    on: {
      "not-activated": _vm.gotoVerification,
      "activated": _vm.signinAgain,
      "sign-in": _vm.goToSignIn,
      "success": _vm.gotoVerification
    }
  })], 1) : _vm._e(), _vm.tab === 5 ? _c('div', [_c('forgot-password', {
    key: _vm.tab,
    attrs: {
      "from-auth-check": "",
      "email": _vm.form.email || _vm.email
    },
    on: {
      "success": _vm.afterPasswordChanged
    }
  })], 1) : _vm._e(), _vm.tab === 3 ? _c('signin-form', {
    attrs: {
      "email": _vm.form.email
    },
    on: {
      "not-activated": _vm.gotoVerification,
      "forget-password": function forgetPassword($event) {
        _vm.tab = 5;
      },
      "change-email": function changeEmail($event) {
        _vm.tab = 1;
      },
      "not-registered": _vm.goToSignUp,
      "success": _vm.afterSignIn
    }
  }) : _vm._e(), _vm.tab === 4 ? _c('div', [_c('verification-code-checker', {
    attrs: {
      "from-install": "",
      "email": _vm.form.email
    },
    on: {
      "change-email": _vm.changeEmail,
      "success": _vm.afterVerified
    }
  })], 1) : _vm._e()], 1), _c('vue-snack-bar')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }