<template>
  <div>
    <div v-if="!isText">
      <v-btn
        v-if="isPublic && platform === 'android'"
        :style="!icon ? 'width: 130px' : ''"
        tag="a"
        @click.stop="getAndroidLink"
        depressed
        :icon="icon"
        :disabled="getMobileOperatingSystem !== 'android'"
        class="mx-auto"
        :class="{
          'fill-width': fillWidth,
          main: !icon,
          primary: !icon,
          'mt-4': !icon,
        }"
      >
        <div v-if="busy">Downloading...</div>
        <div v-else>
          <div v-if="!icon">
            <v-icon v-if="getMobileOperatingSystem === 'android'" left>
              android
            </v-icon>
            {{
              getMobileOperatingSystem === "android"
                ? "Download"
                : "Available in"
            }}
            <v-icon
              color="primary"
              v-if="getMobileOperatingSystem !== 'android'"
              right
            >
              android
            </v-icon>
          </div>
          <v-icon v-else>mdi-download</v-icon>
        </div>
      </v-btn>
      <v-btn
        v-if="!isPublic && getMobileOperatingSystem === 'android'"
        class="mx-auto"
        :disabled="busy"
        :icon="icon"
        @click="getAndroidLink"
        :class="{
          'fill-width': fillWidth,
          main: !icon,
          primary: !icon,
          'mt-4': !icon,
        }"
      >
        <div v-if="busy">Downloading...</div>
        <div v-else>
          <div v-if="!icon">
            <v-icon left> android </v-icon>
            {{ buttonText || "Install" }}
          </div>
          <v-icon v-else>mdi-download</v-icon>
        </div>
      </v-btn>
      <v-btn
        v-if="isPublic && platform === 'ios'"
        depressed
        :icon="icon"
        :disabled="getMobileOperatingSystem !== 'ios' || busy"
        class="mx-auto"
        :style="!icon ? 'width: 130px' : ''"
        @click="openIOSApp"
        :class="{
          'fill-width': fillWidth,
          main: !icon,
          primary: !icon,
          'mx-auto': !icon,
          'mt-4': !icon,
        }"
      >
        <div v-if="busy">Downloading...</div>
        <div v-else>
          <div v-if="!icon">
            <v-icon v-if="getMobileOperatingSystem === 'ios'" left>
              mdi-apple
            </v-icon>
            {{
              getMobileOperatingSystem === "ios" ? "Install" : "Available in"
            }}
            <v-icon v-if="getMobileOperatingSystem !== 'ios'" right>
              mdi-apple
            </v-icon>
          </div>
          <v-icon v-else> mdi-download</v-icon>
        </div>
      </v-btn>
      <v-btn
        v-if="!isPublic && getMobileOperatingSystem === 'ios'"
        :style="!icon ? 'width: 130px' : ''"
        @click="openIOSApp"
        :disabled="busy"
        :icon="icon"
        :class="{
          'fill-width': fillWidth,
          main: !icon,
          primary: !icon,
          'mx-auto': !icon,
        }"
      >
        <div v-if="!icon">
          <v-icon left> mdi-apple </v-icon>
          {{ busy ? "Downloading..." : buttonText || "Install" }}
        </div>
        <v-icon v-else> mdi-download </v-icon>
      </v-btn>
    </div>
    <Modal v-model="openSettingModal">
      <template #message>
        <v-card-title class="font-weight-bold"
          >Progress in background</v-card-title
        >
        <v-card-text class="message">
          Once you tap on install button, you can check the progress in your
          <b>home screen</b>.
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn @click="openSettingModal = false" class="main primary">
            Ok
          </v-btn>
        </v-card-actions>
      </template>
    </Modal>
    <!-- <div v-else>
      <div v-if="getMobileOperatingSystem === 'ios'">
        If you are not being redirected, tap
        <a @click="openIOSApp"> Open App </a>
      </div>
      <div v-if="getMobileOperatingSystem === 'android'">
        If you are not being redirected, tap
        <a :href="getAndroidLink()"> Open App </a>
      </div>
    </div> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { GET_CURRENT_TIMESTAMP } from "@/store/releases/install.module";
import { segmentEvents } from "@/consts/segmentEventConst";

export default {
  props: {
    buttonText: {
      type: String,
      default: null,
    },
    appId: {
      type: String,
      default: "",
    },
    releaseId: {
      type: String,
      default: "",
    },
    releaseUrl: {
      type: String,
      default: "",
    },
    isText: {
      type: Boolean,
      default: false,
    },
    platform: {
      type: String,
      default: "",
    },
    icon: {
      type: Boolean,
      default: false,
    },
    isPublic: {
      type: Boolean,
      default: false,
    },
    fromExpress: {
      type: Boolean,
      default: false,
    },
    fillWidth: Boolean,
  },
  data() {
    return {
      openSettingModal: false,
      busy: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
    }),
  },
  methods: {
    openIOSApp() {
      this.logEvent(segmentEvents.CLICKED_INSTALL_RELEASE, {
        platform: "ios",
        app_id: this.appId,
        release_id: this.releaseId,
        is_express: this.fromExpress,
      });
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(GET_CURRENT_TIMESTAMP)
        .then((response) => {
          window.location.replace(
            `itms-services://?action=download-manifest&url=${encodeURIComponent(
              this.releaseUrl + "?t=" + response.time || ""
            )}`
          );
          setTimeout(() => {
            this.openSettingModal = true;
          }, 2000);
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
        });
    },
    getAndroidLink() {
      if (this.busy) return;
      this.busy = true;

      // const apkLink = `Intent;scheme=testappio;package=testapp.io;S.browser_fallback_url=https%3A%2F%2Fportal.testapp.io%2Fstart;end`;
      window.open(this.releaseUrl, "_self");
      // if (this.fromExpress) {
      //   if (isDirect) {
      //     window.open(this.releaseUrl, '_self') ;
      //   } else {
      //     return `intent://express/${this.releaseId}#${apkLink}`;
      //   }
      // } else {
      //   if (this.isPublic) {
      //     window.open(
      //       this.releaseUrl + `?userID=${this.currentUser.id || ""}`,
      //       "_self"
      //     );
      //   } else {
      //     return this.releaseId
      //       ? `intent://app/${this.appId}/release/${this.releaseId}#${apkLink}`
      //       : `intent://app/${this.appId}#${apkLink}`;
      //   }
      // }
    },
  },
};
</script>
