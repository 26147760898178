<template>
    <v-snackbar v-model="getSnackBar.show" :color="getSnackBar.color" :timeout="getSnackBar.timeout ? 6000 : -1" style="z-index: 9999" :vertical="
        getSnackBar && getSnackBar.message
            ? getSnackBar.message.includes('<')
            : false
    " class="py-0">
        <v-row>
            <v-col cols="12">
                <span v-if="
                    getSnackBar &&
                    getSnackBar.message &&
                    getSnackBar.message.includes('<')
                " v-html="getSnackBar.message" class="font-weight-bold">
                </span>
                <span v-else class="font-weight-bold">
                    {{ getSnackBar.message }}
                </span>
            </v-col>
            <v-col v-if="!getSnackBar.timeout" cols="12" class="py-0 text-center">
                <div class="mx-auto">
                    <v-btn text class="font-weight-bold text-transform-none" @click="cancelSnackbar(false)">
                        Ok
                    </v-btn>
                </div>
            </v-col>
        </v-row>
    </v-snackbar>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters({
            getSnackBar: "getSnackBar",
        }),
    },
};
</script>
