var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "white fill-height"
  }, [_c('v-app-bar', {
    staticClass: "mb-12 text-center",
    attrs: {
      "flat": "",
      "height": "80",
      "color": "primary"
    }
  }, [_c('v-spacer'), _c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    attrs: {
      "src": "/media/logos/logo_express.png",
      "width": "auto",
      "alt": "TestApp.io Express"
    }
  })]), _c('v-spacer')], 1), _c('v-container', {
    staticClass: "fill-width d-flex flex-column align-center justify-center",
    staticStyle: {
      "height": "calc(100% - 110px)"
    }
  }, [_c('div', {
    staticClass: "fill-width"
  }, [_vm.loading ? _c('content-loader', {
    staticClass: "my-16"
  }) : _vm._e(), _vm.getOpenSigninProcess ? _c('div', [_c('auth-check', {
    attrs: {
      "title": "You need to be authorized",
      "sub-title": "Please follow the process"
    },
    on: {
      "refresh": _vm.getInstallInfo
    }
  })], 1) : _vm._e(), _vm.fromExpress && !_vm.isEmpty(_vm.expressReleaseInfo) ? _c('div', {
    staticClass: "border-primary-light radius-6 fill-width mx-auto",
    staticStyle: {
      "max-width": "500px"
    }
  }, [_c('v-card-text', [_c('div', {
    staticClass: "d-flex"
  }, [_c('AppImage', {
    staticClass: "d-flex justify-center align-center",
    attrs: {
      "appId": _vm.get(_vm.appReleaseInfo, 'id', ''),
      "image": _vm.get(_vm.expressReleaseInfo, 'file.icon', ''),
      "size": "40"
    }
  }), _c('div', {
    staticClass: "mx-4 d-flex flex-column"
  }, [_c('span', {
    staticClass: "black--text bold-text"
  }, [_vm._v(" " + _vm._s(_vm.get(_vm.expressReleaseInfo, "file.name", "")) + " ")]), _c('vue-hoverable-date', {
    attrs: {
      "date": _vm.get(_vm.expressReleaseInfo, 'created_at', '')
    }
  })], 1), _c('v-spacer'), _c('span', {
    staticClass: "d-flex flex-column align-center justify-center text-center align-self-center"
  }, [_c('span', {
    staticClass: "bold-text font-size-h5 mt-n1"
  }, [_vm._v(" " + _vm._s(_vm.formatBytes(_vm.get(_vm.expressReleaseInfo, "file.size", 0))) + " ")])])], 1), _c('v-expand-transition', [_c('div', [_c('v-divider', {
    staticClass: "mb-3 mt-4"
  }), _c('div', [_c('v-row', {
    staticClass: "mb-1",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "bold-text mr-2",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("File name:")]), _c('v-col', [_vm._v(_vm._s(_vm.get(_vm.expressReleaseInfo, "file.file_name", "")))])], 1), _c('v-row', {
    staticClass: "mb-1",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "bold-text mr-2",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("Platform:")]), _c('v-col', [_vm._v(_vm._s(_vm.get(_vm.expressReleaseInfo, "platform", "")))])], 1), _c('v-row', {
    staticClass: "mb-1",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "bold-text mr-2",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("Package:")]), _c('v-col', [_vm._v(_vm._s(_vm.get(_vm.expressReleaseInfo, "file.package", "")))])], 1), _c('v-row', {
    staticClass: "mb-1",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "bold-text mr-2",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("Size:")]), _c('v-col', [_vm._v(_vm._s(_vm.formatBytes(_vm.get(_vm.expressReleaseInfo, "file.size", 0))))])], 1), _c('v-row', {
    staticClass: "mb-1",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "bold-text mr-2",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("Version:")]), _c('v-col', [_vm._v(" " + _vm._s(_vm.get(_vm.expressReleaseInfo, "file.version", "")) + " (" + _vm._s(_vm.get(_vm.expressReleaseInfo, "file.version_code", "")) + ") ")])], 1), _c('v-divider', {
    staticClass: "mb-3 mt-4"
  })], 1), _vm.expressReleaseInfo.platform !== _vm.mobile ? _c('div', {
    staticClass: "text-center"
  }, [_c('qrcode', {
    attrs: {
      "value": _vm.getUrl,
      "options": {
        width: 200
      }
    }
  }), _c('div', {
    staticClass: "mb-2"
  }, [_vm._v(" Scan above QR code from your " + _vm._s(_vm.expressReleaseInfo.platform === "android" ? "android" : "iOS") + " device "), _vm.expressReleaseInfo.platform === 'android' ? _c('div', [_vm._v(" or "), _c('a', {
    attrs: {
      "href": _vm.expressReleaseInfo.url,
      "target": "_blank"
    }
  }, [_vm._v("click here")]), _vm._v(" to the download app ")]) : _vm._e()])], 1) : _vm._e(), _vm.expressReleaseInfo && _vm.expressReleaseInfo.platform === 'ios' && _vm.mobile === 'ios' ? _c('install-button', {
    attrs: {
      "fill-width": "",
      "release-id": _vm.releaseID,
      "from-express": "",
      "release-url": _vm.expressReleaseInfo.url,
      "app-id": _vm.appID
    }
  }) : _vm._e(), _vm.expressReleaseInfo && _vm.expressReleaseInfo.platform === 'android' && _vm.mobile === 'android' ? _c('install-button', {
    attrs: {
      "release-id": _vm.releaseID,
      "from-express": "",
      "release-url": _vm.expressReleaseInfo.url,
      "app-id": _vm.appID
    }
  }) : _vm._e()], 1)])], 1)], 1) : _vm._e(), !_vm.releaseID && !_vm.isEmpty(_vm.installAppInfo) ? _c('div', [_c('install-app-info-card', {
    attrs: {
      "info": _vm.installAppInfo
    }
  })], 1) : !_vm.isEmpty(_vm.appReleaseInfo) ? _c('div', {
    staticClass: "border-primary-light radius-6 fill-width mx-auto",
    staticStyle: {
      "max-width": "500px"
    }
  }, [_c('v-card-text', [_c('div', {
    staticClass: "d-flex"
  }, [_c('AppImage', {
    staticClass: "d-flex justify-center align-center",
    attrs: {
      "appId": _vm.get(_vm.appReleaseInfo, 'id', ''),
      "image": _vm.get(_vm.appReleaseInfo, 'app.image', ''),
      "size": "40"
    }
  }), _c('div', {
    staticClass: "mx-4 d-flex flex-column"
  }, [_c('span', {
    staticClass: "black--text bold-text"
  }, [_vm._v(" " + _vm._s(_vm.get(_vm.appReleaseInfo, "app.name", "")) + " ")]), _c('vue-hoverable-date', {
    attrs: {
      "date": _vm.get(_vm.appReleaseInfo, 'created_at', '')
    }
  })], 1), _c('v-spacer'), _c('span', {
    staticClass: "d-flex flex-column align-center justify-center text-center align-self-center"
  }, [_c('span', {
    staticClass: "bold-text font-size-h5 mt-n1"
  }, [_vm._v(" " + _vm._s(_vm.formatBytes(_vm.get(_vm.appReleaseInfo, "file.size", 0))) + " ")])])], 1), _c('v-expand-transition', [_c('div', [_c('v-divider', {
    staticClass: "mb-3 mt-4"
  }), _c('div', [_c('v-row', {
    staticClass: "mb-1",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "bold-text mr-2",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("File name:")]), _c('v-col', [_vm._v(_vm._s(_vm.get(_vm.appReleaseInfo, "file.name", "")))])], 1), _c('v-row', {
    staticClass: "mb-1",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "bold-text mr-2",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("Package:")]), _c('v-col', [_vm._v(_vm._s(_vm.get(_vm.appReleaseInfo, "file.package", "")))])], 1), _c('v-row', {
    staticClass: "mb-1",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "bold-text mr-2",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("Size:")]), _c('v-col', [_vm._v(_vm._s(_vm.formatBytes(_vm.get(_vm.appReleaseInfo, "file.size", 0))))])], 1), _c('v-row', {
    staticClass: "mb-1",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "bold-text mr-2",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("Version:")]), _c('v-col', [_vm._v(" " + _vm._s(_vm.get(_vm.appReleaseInfo, "file.version", "")) + " (" + _vm._s(_vm.get(_vm.appReleaseInfo, "file.version_code", "")) + ") ")])], 1), _c('v-divider', {
    staticClass: "mb-3 mt-4"
  }), _c('div', {
    staticClass: "d-flex align-center black--text"
  }, [_c('ProfileImage', {
    attrs: {
      "image": _vm.get(_vm.appReleaseInfo, 'member.image', ''),
      "alt": _vm.get(_vm.appReleaseInfo, 'member.name', '')
    }
  }), _c('span', {
    staticClass: "bold-text ml-3"
  }, [_vm._v(" By " + _vm._s(_vm.get(_vm.appReleaseInfo, "member.name", "")) + " ")])], 1)], 1), _vm.appReleaseInfo.platform !== _vm.mobile ? _c('div', {
    staticClass: "text-center"
  }, [_c('qrcode', {
    attrs: {
      "value": _vm.appReleaseInfo.platform == 'android' ? _vm.appReleaseInfo.url : _vm.getUrl,
      "options": {
        width: 200
      }
    }
  }), _c('div', {
    staticClass: "mb-2"
  }, [_vm._v(" Scan above QR code from your " + _vm._s(_vm.appReleaseInfo.platform === "android" ? "android" : "iOS") + " device "), _vm.appReleaseInfo.platform === 'android' ? _c('div', [_vm._v(" or "), _c('a', {
    attrs: {
      "href": _vm.appReleaseInfo.url,
      "target": "_blank"
    }
  }, [_vm._v("click here ")]), _vm._v(" to the download app ")]) : _vm._e()])], 1) : _vm._e(), _vm.appReleaseInfo && _vm.appReleaseInfo.platform === 'ios' && _vm.mobile === 'ios' ? _c('install-button', {
    attrs: {
      "is-public": _vm.appReleaseInfo.app && _vm.appReleaseInfo.app.public,
      "platform": _vm.appReleaseInfo.platform,
      "release-url": _vm.appReleaseInfo.url,
      "release-id": _vm.releaseID,
      "app-id": _vm.appID
    }
  }) : _vm._e(), _vm.appReleaseInfo && _vm.appReleaseInfo.platform === 'android' && _vm.mobile === 'android' ? _c('install-button', {
    attrs: {
      "release-id": _vm.releaseID,
      "platform": _vm.appReleaseInfo.platform,
      "release-url": _vm.appReleaseInfo.url,
      "is-public": _vm.appReleaseInfo.app && _vm.appReleaseInfo.app.public,
      "app-id": _vm.appID
    }
  }) : _vm._e()], 1)])], 1)], 1) : _vm._e()], 1), _c('div', {
    staticClass: "py-6 flex-grow-1 d-flex flex-column flex-root justify-center align-center"
  }, [_vm.showPermissionError && _vm.getAuthMessage.message ? _c('vue-inline-alert', {
    staticClass: "my-3",
    attrs: {
      "message": _vm.getAuthMessage.message,
      "messageType": _vm.getAuthMessage.type
    }
  }) : _vm._e(), _c('div', {
    staticClass: "my-8"
  }, [_vm.permissionMessage ? _c('inline-svg', {
    staticClass: "svg-icon",
    attrs: {
      "src": "/media/placeholders/no-devices".concat(_vm.$vuetify.theme.dark ? '-dark' : '', ".svg")
    }
  }) : _vm._e(), _c('p', {
    staticClass: "mt-4 empty-title bold-text text-dark text-center"
  }, [_vm._v(" " + _vm._s(_vm.permissionMessage) + " ")])], 1), _c('span', [_vm.errorMessage || _vm.message ? _c('vue-inline-alert', {
    attrs: {
      "message": _vm.errorMessage || _vm.message,
      "messageType": _vm.errorMessage ? 'error' : 'info',
      "no-close-btn": ""
    }
  }) : _vm._e(), !_vm.errorMessage ? _c('install-button', {
    attrs: {
      "is-text": "",
      "app-id": _vm.appID,
      "release-id": _vm.releaseID
    }
  }) : _vm._e()], 1), _vm.showPermissionError ? _c('div', [_c('v-row', {
    staticClass: "text-center mb-15 pb-7"
  }, [_c('v-col', [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('v-btn', {
    staticClass: "primary install"
  }, [_vm._v("Dashboard")])], 1)], 1), _vm.mobile != 'ios' ? _c('v-col', [_c('a', {
    attrs: {
      "href": "intent://#Intent;scheme=testappio://;package=testapp.io;S.browser_fallback_url=https%3A%2F%2Fportal.testapp.io%2Fstart;end"
    }
  }, [_c('img', {
    staticClass: "install-img",
    attrs: {
      "src": "/media/svg/google-play.svg"
    }
  })])]) : _vm._e(), _vm.mobile != 'android' ? _c('v-col', [_c('a', {
    attrs: {
      "href": "https://apps.apple.com/us/app/testapp-io/id1518972541"
    }
  }, [_c('img', {
    staticClass: "install-img",
    attrs: {
      "src": "/media/svg/app-store.svg"
    }
  })])]) : _vm._e()], 1)], 1) : _vm._e(), _c('br'), _vm.releaseID ? _c('v-row', {
    staticClass: "faq",
    attrs: {
      "justify": "center"
    }
  }, [!_vm.loading && !_vm.getOpenSigninProcess && !_vm.errorMessage && !_vm.permissionMessage && _vm.getPlatform ? _c('v-col', {
    staticClass: "questions",
    attrs: {
      "cols": "12",
      "lg": "5"
    }
  }, [_c('h1', {
    staticClass: "display-1 text-center"
  }, [_vm._v("FAQ")]), _c('br'), _c('v-expansion-panels', {
    attrs: {
      "flat": ""
    }
  }, [_vm.getPlatform === 'android' ? [_c('v-expansion-panel', [_c('v-expansion-panel-header', {
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-icon', {
          staticClass: "ml-4",
          attrs: {
            "left": "",
            "large": ""
          }
        }, [_vm._v("expand_more")])];
      },
      proxy: true
    }], null, false, 2969559647)
  }, [_vm._v(" While installing the app on Android, I get a security warning ")]), _c('v-expansion-panel-content', [_c('div', [_vm._v(" On Android 8 or higher, you get a dialog such as "), _c('b', [_vm._v("\"Chrome, For your security, your phone is not allowed to install unknown apps from this source\"")]), _vm._v(". "), _c('br'), _c('br'), _vm._v(" Click the "), _c('b', [_vm._v("Settings")]), _vm._v(" button of that dialog and toggle "), _c('b', [_vm._v("Allow from this source")]), _vm._v(". "), _c('br'), _c('br'), _vm._v(" Alternatively you can navigate to "), _c('b', [_vm._v("Phone Settings > Apps & notifications")]), _vm._v(". "), _c('br'), _c('br'), _vm._v(" Find the browser application in the list, tap "), _c('b', [_vm._v("Advanced")]), _vm._v(" then "), _c('b', [_vm._v("Install unknown apps")]), _vm._v(" then toggle "), _c('b', [_vm._v("Allow from this source")]), _vm._v(". "), _c('br'), _c('br'), _vm._v(" On Android 7 or lower, navigate to the device "), _c('b', [_vm._v("Settings")]), _vm._v(" then into the "), _c('b', [_vm._v("Security")]), _vm._v(" category. Under the "), _c('b', [_vm._v("Device Administration")]), _vm._v(" category, toggle "), _c('b', [_vm._v("Unknown Sources")]), _vm._v(". Then try to install the application again. Please note that some device manufacturers might customize the settings UI and thus menu entries can have different names or be at a different location. ")])])], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', {
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-icon', {
          staticClass: "ml-4",
          attrs: {
            "left": "",
            "large": ""
          }
        }, [_vm._v("expand_more")])];
      },
      proxy: true
    }], null, false, 2969559647)
  }, [_vm._v(" While installing the app, I get a \"There Was a Problem Parsing the Package\" error ")]), _c('v-expansion-panel-content', [_c('div', [_vm._v(" This error could be caused by one of the following reasons: "), _c('br'), _c('br'), _c('ul', [_c('li', [_vm._v("File may be downloaded incompletely")]), _c('li', [_vm._v(" Application might be not suitable for your hardware or OS version ")]), _c('li', [_vm._v("Security settings")]), _c('li', [_vm._v("Corrupted APK file")]), _c('li', [_vm._v("Name of the package is changed after signing")])]), _c('br'), _c('p', [_vm._v(" To troubleshoot, try reinstalling the app. If that doesn't work, contact the app developer to help resolve the issue. ")])])])], 1)] : _vm._e(), _vm.getPlatform === 'ios' ? [_c('v-expansion-panel', [_c('v-expansion-panel-header', {
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-icon', {
          staticClass: "ml-4",
          attrs: {
            "left": "",
            "large": ""
          }
        }, [_vm._v("expand_more")])];
      },
      proxy: true
    }], null, false, 2969559647)
  }, [_vm._v(" I get the message “Untrusted Enterprise Developer” ")]), _c('v-expansion-panel-content', [_c('div', [_vm._v(" In order for the app to be available for use, you will need to trust the developer's certificate. "), _c('br'), _c('br'), _vm._v(" From your phone’s home screen, tap "), _c('b', [_vm._v("Settings > General > Profiles or Profiles & Device Management")]), _vm._v(". "), _c('br'), _vm._v("Under the "), _c('b', [_vm._v("Enterprise App")]), _vm._v(" heading, you see will see a profile for the developer. Tap the name of the developer profile and then confirm you trust them. You should now be able to launch the app. ")])])], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', {
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-icon', {
          staticClass: "ml-4",
          attrs: {
            "left": "",
            "large": ""
          }
        }, [_vm._v("expand_more")])];
      },
      proxy: true
    }], null, false, 2969559647)
  }, [_vm._v(" While installing the app, I get an “Unable to Download App” error, and the app is not installed ")]), _c('v-expansion-panel-content', [_c('div', [_vm._v(" This error could be caused by many reasons, and you should contact the app developer to help resolve the issue."), _c('br'), _c('br'), _vm._v(" If you are the app developer, this error could be caused by one of the following reasons: "), _c('br'), _c('ul', [_c('li', [_vm._v(" UDID is not included in the provisioning profile ")]), _c('li', [_vm._v(" The build was incorrectly signed or has broken entitlements ")]), _c('li', [_vm._v(" Device or iOS version is incompatible with the build ")]), _c('li', [_vm._v("Device storage is full")]), _c('li', [_vm._v("Actual download error")]), _c('li', [_vm._v(" App is over 50 MB and downloaded over a cellular connection ")])])])])], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', {
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-icon', {
          staticClass: "ml-4",
          attrs: {
            "left": "",
            "large": ""
          }
        }, [_vm._v("expand_more")])];
      },
      proxy: true
    }], null, false, 2969559647)
  }, [_vm._v(" After installing the app, It appears to download successfully but I am unable to open it ")]), _c('v-expansion-panel-content', [_c('div', [_vm._v(" It could be because your UDID is missingg from provisioning profile. "), _c('br'), _vm._v(" Go to "), _c('a', {
    attrs: {
      "href": "/udid"
    }
  }, [_vm._v("install UDID page")]), _vm._v(" in safari and continue from there ")])])], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', {
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-icon', {
          staticClass: "ml-4",
          attrs: {
            "left": "",
            "large": ""
          }
        }, [_vm._v("expand_more")])];
      },
      proxy: true
    }], null, false, 2969559647)
  }, [_vm._v(" When I press the “Install” button, the installation alert never shows up and the app never installs on my device ")]), _c('v-expansion-panel-content', [_c('div', [_vm._v(" If you have previously installed the app from the App Store, and it carries the same version of the app that is being installed, there will be no alert to confirm the installation."), _c('br'), _c('br'), _vm._v(" As a workaround, please try uninstalling the existing version of the app, and then tap the Install button again. ")])])], 1)] : _vm._e()], 2)], 1) : _vm._e()], 1) : _vm._e()], 1), _c('vue-snackbar')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }