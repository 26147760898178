<template>
  <v-form @submit.prevent="onSubmit">
    <div class="mb-4">
      <div class="bold-text font-size-h3">Email</div>
      <div id="signinEmail">
        {{ form.email }}
        <span
          @click.stop="$emit('change-email')"
          class="primary--text cursor-pointer"
        >
          (Change)
        </span>
      </div>
    </div>

    <v-text-field
      label="Password"
      placeholder="Your password"
      filled
      autofocus
      id="signinPassword"
      v-model="form.password"
      v-validate="'required|max:27|min:3'"
      v-bind="veeValidate('Password', 'Password')"
      persistent-hint
      hide-details
      type="password"
    />
    <div class="d-flex mb-8">
      <v-spacer />
      <router-link
        v-if="defaultSignin"
        class="primary--text font-size-h4 bold-text mt-5"
        :to="{ name: 'forgot-password', query: { email: form.email || '' } }"
      >
        Forgot Password?
      </router-link>
      <div
        v-else
        class="primary--text cursor-pointer font-size-h4 bold-text mt-5"
        @click="$emit('forget-password')"
      >
        Forgot Password?
      </div>
    </div>
    <v-btn
      class="main primary mx-auto"
      type="submit"
      id="signinSubmit"
      :disabled="errors.any() || busy || !form.email || !form.password"
      :loading="busy"
    >
      sign in
    </v-btn>
    <social-auth
      @loading="busy = $event"
      button-text="Sign in with"
      @success="$emit(' success')"
      :default-signin="defaultSignin"
    ></social-auth>
  </v-form>
</template>

<script>
import veeValidate from "@/mixins/veeValidate";
import { SIGNIN } from "@/store/users/auth.module";
import { isEmpty } from "@/core/services/helper.service";
import { mapGetters, mapMutations } from "vuex";
import SocialAuth from "@/view/components/User/SocialAuth";
export default {
  components: { SocialAuth },
  mixins: [veeValidate],
  props: {
    defaultSignin: {
      type: Boolean,
      default: false,
    },
    email: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: {
        email: "",
      },
      busy: false,
      errorMessage: "",
    };
  },
  watch: {
    "$route.query.email"() {
      this.setEmail();
    },
  },
  computed: {
    ...mapGetters({
      getAuthMessage: "getAuthMessage",
      getNavigateTo: "getNavigateTo",
    }),
  },
  created() {
    this.setEmail();
    if (isEmpty(this.$route.params)) {
      this.setAuthMessage({ message: "", type: "" });
    }
  },
  methods: {
    resetForm() {
      this.form = {
        email: null,
        password: null,
      };
    },
    ...mapMutations({
      setAuthMessage: "setAuthMessage",
      setActivateErrorMessage: "setActivateErrorMessage",
    }),
    setEmail() {
      this.form.email = this.email || this.$route.params.email || "";
    },
    async onSubmit() {
      this.setAuthMessage({ message: "", type: "" });
      if (this.errors.any() || this.busy) {
        return;
      }
      this.busy = true;
      // clear existing errors
      this.errorMessage = null;
      await this.$store
        .dispatch(SIGNIN, {
          ...this.form,
          invite_id: this.$route.params.invite_id || "",
        })
        .then((response) => {
          this.setAuthMessage({ message: response.message, type: "success" });
          if (!isEmpty(response.invite_error)) {
            this.setActivateErrorMessage(response.invite_error);
          }
          if (this.defaultSignin) {
            this.$nextTick(() => {
              if (response && !response.team) {
                this.$router.push({ name: "welcome" }).catch(() => {});
              } else {
                if (this.getNavigateTo === "team_plans") {
                  this.$router.push({ name: "team-usage" }).catch();
                } else {
                  this.$router.push({ name: "dashboard" }).catch(() => {});
                }
              }
            });
          } else {
            this.$emit("success", response.message);
          }
          this.busy = false;
        })
        .catch((err) => {
          this.busy = false;
          if (!this.defaultSignin) {
            this.notifyErrorMessage(err.message);
          }
          this.setAuthMessage({
            message: err.message,
            type: "error",
          });
          if (err.status === 1) {
            if (this.defaultSignin) {
              this.$router
                .push({
                  name: "signup",
                  params: { email: this.form.email },
                })
                .catch(() => {});
            }
            this.$emit("not-registered", this.form.email);
          } else if (err.status === 2) {
            if (this.defaultSignin) {
              this.$router
                .push({
                  name: "verify",
                  query: { email: this.form.email },
                })
                .catch(() => {});
            }
            this.$emit("not-activated", this.form.email);
          }
        });
    },
  },
};
</script>

<style scoped>
.background {
  background-color: white;
}
</style>
