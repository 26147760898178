var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.isText ? _c('div', [_vm.isPublic && _vm.platform === 'android' ? _c('v-btn', {
    staticClass: "mx-auto",
    class: {
      'fill-width': _vm.fillWidth,
      main: !_vm.icon,
      primary: !_vm.icon,
      'mt-4': !_vm.icon
    },
    style: !_vm.icon ? 'width: 130px' : '',
    attrs: {
      "tag": "a",
      "depressed": "",
      "icon": _vm.icon,
      "disabled": _vm.getMobileOperatingSystem !== 'android'
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.getAndroidLink.apply(null, arguments);
      }
    }
  }, [_vm.busy ? _c('div', [_vm._v("Downloading...")]) : _c('div', [!_vm.icon ? _c('div', [_vm.getMobileOperatingSystem === 'android' ? _c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" android ")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.getMobileOperatingSystem === "android" ? "Download" : "Available in") + " "), _vm.getMobileOperatingSystem !== 'android' ? _c('v-icon', {
    attrs: {
      "color": "primary",
      "right": ""
    }
  }, [_vm._v(" android ")]) : _vm._e()], 1) : _c('v-icon', [_vm._v("mdi-download")])], 1)]) : _vm._e(), !_vm.isPublic && _vm.getMobileOperatingSystem === 'android' ? _c('v-btn', {
    staticClass: "mx-auto",
    class: {
      'fill-width': _vm.fillWidth,
      main: !_vm.icon,
      primary: !_vm.icon,
      'mt-4': !_vm.icon
    },
    attrs: {
      "disabled": _vm.busy,
      "icon": _vm.icon
    },
    on: {
      "click": _vm.getAndroidLink
    }
  }, [_vm.busy ? _c('div', [_vm._v("Downloading...")]) : _c('div', [!_vm.icon ? _c('div', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" android ")]), _vm._v(" " + _vm._s(_vm.buttonText || "Install") + " ")], 1) : _c('v-icon', [_vm._v("mdi-download")])], 1)]) : _vm._e(), _vm.isPublic && _vm.platform === 'ios' ? _c('v-btn', {
    staticClass: "mx-auto",
    class: {
      'fill-width': _vm.fillWidth,
      main: !_vm.icon,
      primary: !_vm.icon,
      'mx-auto': !_vm.icon,
      'mt-4': !_vm.icon
    },
    style: !_vm.icon ? 'width: 130px' : '',
    attrs: {
      "depressed": "",
      "icon": _vm.icon,
      "disabled": _vm.getMobileOperatingSystem !== 'ios' || _vm.busy
    },
    on: {
      "click": _vm.openIOSApp
    }
  }, [_vm.busy ? _c('div', [_vm._v("Downloading...")]) : _c('div', [!_vm.icon ? _c('div', [_vm.getMobileOperatingSystem === 'ios' ? _c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-apple ")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.getMobileOperatingSystem === "ios" ? "Install" : "Available in") + " "), _vm.getMobileOperatingSystem !== 'ios' ? _c('v-icon', {
    attrs: {
      "right": ""
    }
  }, [_vm._v(" mdi-apple ")]) : _vm._e()], 1) : _c('v-icon', [_vm._v(" mdi-download")])], 1)]) : _vm._e(), !_vm.isPublic && _vm.getMobileOperatingSystem === 'ios' ? _c('v-btn', {
    class: {
      'fill-width': _vm.fillWidth,
      main: !_vm.icon,
      primary: !_vm.icon,
      'mx-auto': !_vm.icon
    },
    style: !_vm.icon ? 'width: 130px' : '',
    attrs: {
      "disabled": _vm.busy,
      "icon": _vm.icon
    },
    on: {
      "click": _vm.openIOSApp
    }
  }, [!_vm.icon ? _c('div', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-apple ")]), _vm._v(" " + _vm._s(_vm.busy ? "Downloading..." : _vm.buttonText || "Install") + " ")], 1) : _c('v-icon', [_vm._v(" mdi-download ")])], 1) : _vm._e()], 1) : _vm._e(), _c('Modal', {
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('v-card-title', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Progress in background")]), _c('v-card-text', {
          staticClass: "message"
        }, [_vm._v(" Once you tap on install button, you can check the progress in your "), _c('b', [_vm._v("home screen")]), _vm._v(". ")]), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          staticClass: "main primary",
          on: {
            "click": function click($event) {
              _vm.openSettingModal = false;
            }
          }
        }, [_vm._v(" Ok ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openSettingModal,
      callback: function callback($$v) {
        _vm.openSettingModal = $$v;
      },
      expression: "openSettingModal"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }