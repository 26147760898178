var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-snackbar', {
    staticClass: "py-0",
    staticStyle: {
      "z-index": "9999"
    },
    attrs: {
      "color": _vm.getSnackBar.color,
      "timeout": _vm.getSnackBar.timeout ? 6000 : -1,
      "vertical": _vm.getSnackBar && _vm.getSnackBar.message ? _vm.getSnackBar.message.includes('<') : false
    },
    model: {
      value: _vm.getSnackBar.show,
      callback: function callback($$v) {
        _vm.$set(_vm.getSnackBar, "show", $$v);
      },
      expression: "getSnackBar.show"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.getSnackBar && _vm.getSnackBar.message && _vm.getSnackBar.message.includes('<') ? _c('span', {
    staticClass: "font-weight-bold",
    domProps: {
      "innerHTML": _vm._s(_vm.getSnackBar.message)
    }
  }) : _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.getSnackBar.message) + " ")])]), !_vm.getSnackBar.timeout ? _c('v-col', {
    staticClass: "py-0 text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "mx-auto"
  }, [_c('v-btn', {
    staticClass: "font-weight-bold text-transform-none",
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.cancelSnackbar(false);
      }
    }
  }, [_vm._v(" Ok ")])], 1)]) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }