var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "border-primary-light radius-6 fill-width mx-auto",
    staticStyle: {
      "max-width": "500px"
    }
  }, [_c('v-card-text', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "ma-2"
  }, [_c('v-col', {
    staticClass: "px-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('AppImage', {
    staticClass: "d-flex justify-center align-center",
    attrs: {
      "appId": _vm.get(_vm.appInfo, 'id', ''),
      "image": _vm.get(_vm.appInfo, 'image', ''),
      "size": "40"
    }
  })], 1), _c('v-col', {
    staticClass: "text-left px-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "mx-4 d-flex flex-column"
  }, [_c('span', {
    staticClass: "black--text bold-text"
  }, [_vm._v(" " + _vm._s(_vm.get(_vm.appInfo, "name", "")) + " ")]), _c('vue-hoverable-date', {
    attrs: {
      "date": _vm.get(_vm.appInfo, 'created_at', '')
    }
  })], 1)])], 1)], 1), _c('v-col', {
    staticClass: "text-center text-h6",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Latest releases ")]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [!_vm.latestReleases.length ? _c('v-col', [_c('inline-svg', {
    staticClass: "mt-6 svg-icon empty_icon",
    attrs: {
      "src": "/media/placeholders/no-devices".concat(_vm.$vuetify.theme.dark ? '-dark' : '', ".svg")
    }
  }), _c('p', {
    staticClass: "mt-4 empty-title bodyFont bold-text text-dark text-center"
  }, [_vm._v(" No latest releases, just yet... ")])], 1) : [_c('v-expansion-panels', {
    attrs: {
      "flat": ""
    }
  }, [_vm._l(_vm.latestReleases, function (release, index) {
    return [_c('v-expansion-panel', {
      key: index,
      staticClass: "transparentBackground lightBorder"
    }, [_c('v-expansion-panel-header', {
      staticClass: "lightBackground",
      attrs: {
        "disable-icon-rotate": ""
      },
      scopedSlots: _vm._u([{
        key: "actions",
        fn: function fn() {
          return [_c('div', [_c('div', [_vm.appInfo.public ? _c('div', [_c('install-button', {
            attrs: {
              "is-public": "",
              "icon": "",
              "release-url": release.url,
              "platform": release.platform
            }
          })], 1) : _c('div', [release.platform === 'android' && _vm.getMobileOperatingSystem === 'android' ? _c('install-button', {
            attrs: {
              "icon": "",
              "release-url": release.url
            }
          }) : _vm._e(), release.platform === 'ios' && _vm.getMobileOperatingSystem === 'ios' ? _c('install-button', {
            attrs: {
              "icon": "",
              "release-url": release.url
            }
          }) : _vm._e()], 1)]), _c('div', {
            staticClass: "caption grey-text text-center"
          }, [_vm._v(" " + _vm._s(_vm.formatBytes(_vm.get(release, "info.size", 0) || 0)) + " ")])])];
        },
        proxy: true
      }], null, true)
    }, [_c('v-row', [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-icon', {
      domProps: {
        "textContent": _vm._s(release.platform === 'android' ? 'mdi-android' : 'mdi-apple')
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('div', [_vm._v(" " + _vm._s("v" + _vm.get(release, "info.version", "0")) + " ")]), _c('div', {
      staticClass: "grey-text caption"
    }, [_c('vue-hoverable-date', {
      attrs: {
        "date": release.created_at
      }
    })], 1)])], 1)], 1), _c('v-expansion-panel-content', {
      staticClass: "lightBackground"
    }, [_c('div', {
      staticClass: "mb-2"
    }, [_c('span', {
      staticClass: "bold-text mr-3 px-0"
    }, [_vm._v(" Package: ")]), _vm._v(" " + _vm._s(release.info.package) + " ")]), _c('div', {
      staticClass: "mb-2"
    }, [_c('span', {
      staticClass: "bold-text mr-3 px-0"
    }, [_vm._v(" Version: ")]), _vm._v(" " + _vm._s(_vm.get(release, "info.version", "0")) + " (" + _vm._s(_vm.get(release, "info.version_code", "0")) + ") ")]), _c('div', {
      staticClass: "mb-2"
    }, [_c('span', {
      staticClass: "bold-text mr-3 px-0"
    }, [_vm._v(" By: ")]), _vm._v(" " + _vm._s(_vm.get(release, "user.name", "")) + " ")]), _c('div', {
      staticClass: "mb-2"
    }, [_c('span', {
      staticClass: "bold-text mr-3 px-0"
    }, [_vm._v(" Note: ")]), _vm._v(" " + _vm._s(release.note || "-") + " ")]), _vm.appInfo.public ? _c('div', [_c('install-button', {
      attrs: {
        "is-public": "",
        "release-url": release.url,
        "platform": release.platform
      }
    })], 1) : _c('div', [release.platform === 'android' && _vm.getMobileOperatingSystem === 'android' ? _c('install-button', {
      attrs: {
        "release-url": release.url
      }
    }) : _vm._e(), release.platform === 'android' && _vm.getMobileOperatingSystem !== 'android' ? _c('div', {
      staticClass: "text-center"
    }, [_c('qrcode', {
      attrs: {
        "value": release.url,
        "options": {
          width: 200
        }
      }
    }), _c('div', {
      staticClass: "mb-2"
    }, [_vm._v(" Scan this QR code from your android device or "), _c('a', {
      attrs: {
        "href": release.url,
        "target": "_blank"
      }
    }, [_vm._v("click here ")]), _vm._v(" download app ")])], 1) : _vm._e(), release.platform === 'ios' && _vm.getMobileOperatingSystem === 'ios' ? _c('install-button', {
      attrs: {
        "release-url": release.url
      }
    }) : _vm._e(), release.platform === 'ios' && _vm.getMobileOperatingSystem !== 'ios' ? _c('div', {
      staticClass: "text-center"
    }, [_c('qrcode', {
      attrs: {
        "value": release.url,
        "options": {
          width: 200
        }
      }
    }), _c('div', {
      staticClass: "mb-2"
    }, [_vm._v(" Scan this QR code from your iOS device or "), _c('a', {
      attrs: {
        "href": release.url,
        "target": "_blank"
      }
    }, [_vm._v("click here ")]), _vm._v(" download app ")])], 1) : _vm._e()], 1)])], 1)];
  })], 2)]], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }